import Banner from "../components/banner/banner.components";
import Services from "../components/Services/Service";
import Contact from "../components/Contact/Contact";
import Recruit from "../components/Recruit/Recruit";
import MainLayout from "../layouts/MainLayout";
import AboutUs from "../components/about/about.components";
import HomeNav from "../components/Navbar/HomeNavBar";

import { AnimationOnScroll } from 'react-animation-on-scroll';


const Home = () => {
  return (
    <MainLayout>
      <HomeNav />
      <div className="p-0 m-0 overflow-y-auto">

        <section id="Banner">
          <Banner />
        </section>
        <div className="">
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <section id="Services">
              <Services />
            </section>
          </AnimationOnScroll>
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <section id="Recruit">
              <Recruit />
            </section>
          </AnimationOnScroll>
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInDown">
            <section id="AboutUs">
              <AboutUs />
            </section>
          </AnimationOnScroll>
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInDown">
            <section id="Contact-us">
              <Contact />
            </section>
          </AnimationOnScroll>
          {/* <section id="Our-project">
        <Project />
        </section>

        <section id="Contact-us">
        <ContactForm />
        </section> */}
        </div>



      </div>
    </MainLayout>
  );
};

export default Home;
