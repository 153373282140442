// import fastFood from "../../assets/logo/fast-food.png";
// import fast from "../../assets/logo/fast.png";
// import production from "../../assets/logo/production.png";
// import software from "../../assets/logo/software.png";
// import sv1 from '/image/image-sv1.png';
// import sv2 from '/image/image-sv2.png';
// import sv3 from '/image/image-sv3.png';
import './ServicesStyles.css';
// import oo from '/logo/logo1.jpg';
// import chomchom from '/logo/logo.jpg';
import { useState } from 'react';
import ServiceBox from './ServiceBox';



const Services = () => {
  const [showProduct, setShowProduct] = useState(1);

  return (
    <>
      <div className="services-container">
        <div className="services-title">
          <div className="flex md:w-[80%] w-[90%] m-auto items-center ">
            <b className="br-service"></b>
            <span className="md:text-[50px] text-[25px] font-bold text-[#0e294d] md:mx-[25px] mx-[15px] md:pb-[15px] pb-[5px]">
              Giải pháp của chúng tôi
            </span>
            <b className="br-service"></b>

          </div>
          <p className="md:text-[21px] text-[15px] text-[#435a6e] mx-[50px] md:mx-0">
            Chúng tôi mang lại giải pháp công nghệ phù hợp, hiệu quả, tiết kiệm
          </p>
        </div>
        <div className="services-content">
          <div className="w-[32%] services-content-item border-r lg:border-r-[#d6e9f3] border-r-[#fff] " >
            <img src='/image/image-sv4.png' className="md:w-[180px] w-[150px] m-auto pt-[35px] relative img-item-sevice cursor-pointer"
              alt="Không có hình ảnh để hiểu thị" onClick={() => { setShowProduct(1) }} />
            <h2 className="uppercase text-[#0e294d] text-center text-[25px] font-medium mt-[10px]">F&B</h2>
            <div className="mt-[10px] pr-[5px] pl-[20px]">
              <h3 className="text-[#0e294d] text-[17px]">Công nghệ số quy trình phục vụ (Nhanh, tiết kiệm, chính xác) với giá cả hợp lý</h3>
              <ul className="list-disc pl-[20px] text-[16px] text-[#0e294d] pt-[5px]">
                <li className="pt-[10px]">Tốt độ nhanh, vượt trội</li>
                <li className="pt-[10px]">Tiết kiệm nguồn nhân lực và chi phí</li>
                <li className="pt-[10px]">Dễ quản lý kiểm soát các khâu trong quy trình</li>
                <li className="pt-[10px]">Sử dụng đa nền tảng</li>
              </ul>
              <p className='pt-3 pl-2 text-[#0e294d] hover:text-red-600 cursor-pointer' onClick={() => { setShowProduct(1) }} >Xem thêm</p>
            </div>
          </div>
          <div className="w-[32%] services-content-item border-r lg:border-r-[#d6e9f3] border-r-[#fff] ">
            <img className="md:w-[180px] w-[150px] m-auto pt-[35px] relative img-item-sevice cursor-pointer"
              src='/image/image-sv1.png' alt="Không có hình ảnh để hiểu thị" onClick={() => { setShowProduct(2) }} />
            <h2 className="uppercase text-[#0e294d] text-center text-[25px] font-medium mt-[10px] ">Manufacturing</h2>
            <div className="mt-[10px] pr-[5px] pl-[20px]">
              <h3 className="text-[#0e294d] text-[17px]">Tự động hóa quy trình vận hành thông minh. Quản trị theo xu thế 4.0</h3>
              <ul className="list-disc pl-[20px] text-[16px] text-[#0e294d] pt-[5px]">
                <li className="pt-[10px]">Dữ liệu được ghi nhận ở tất cả các khâu</li>
                <li className="pt-[10px]">Công nghệ hóa quy trình thông minh</li>
                <li className="pt-[10px]">Dễ dàng theo dõi, tra cứu tồn kho của từng loại hàng hóa</li>
                <li className="pt-[10px]">Phân quyền chi tiết theo người dùng</li>
              </ul>
              <p className='pt-3 pl-2 text-[#0e294d] hover:text-red-600 cursor-pointer' onClick={() => { setShowProduct(2) }} >Xem thêm</p>
            </div>
          </div>
          <div className="w-[32%] services-content-item" >
            <img src='/image/image-sv2.png'
              className="md:w-[180px] w-[150px] m-auto pt-[35px] img-item-sevice relative cursor-pointer"
              alt="Không có hình ảnh để hiểu thị" onClick={() => { setShowProduct(3) }} />
            <h2 className="uppercase text-[#0e294d] text-center text-[25px] font-medium mt-[10px]">Insurance</h2>
            <div className="mt-[10px] pr-[5px] pl-[20px]">
              <h3 className="text-[#0e294d] text-[17px]">Thủ tục giấy tờ tự động. Giao diện xử lý nhanh gọn, dễ dùng. Thân thiện tiếp cận</h3>
              <ul className="list-disc pl-[20px] text-[16px] text-[#0e294d] pt-[5px]">
                <li className="pt-[10px]">Chuẩn hóa dữ liệu chuyên nghiệp</li>
                <li className="pt-[10px]">Thực hiện thủ tục giấy tờ một cách tự dộng, chuyên nghiệp</li>
                <li className="pt-[10px]">Tốc độ xử lý nhanh, thao tác dễ dàng</li>
                <li className="pt-[10px]">Sử dụng mọi nền tảng</li>
              </ul>
              <p className='pt-3 pl-2 text-[#0e294d] hover:text-red-600 cursor-pointer' onClick={() => { setShowProduct(3) }} >Xem thêm</p>
            </div>
          </div>
        </div>
        {/* <div className='flex items-center justify-center md:gap-[5%] gap-[10%]'>
          <div className='cursor-pointer hover:text-[#fb3939]' >
            <img src='/image/image-sv4.png' className="md:w-[160px] w-[60px] m-auto pt-[35px] relative img-item-sevice" alt="Không có hình ảnh để hiểu thị" />
            <h3 className='text-center mt-[5px] text-[20px]'>F&B</h3>
          </div>
          <div className='cursor-pointer hover:text-[#fb3939]' >
            <img src='/image/image-sv1.png' className="md:w-[160px] w-[60px] m-auto pt-[35px] relative img-item-sevice" alt="Không có hình ảnh để hiểu thị" />
            <h3 className='text-center mt-[5px] text-[20px]'>Manufacturing</h3>
          </div>
          <div className='cursor-pointer hover:text-[#fb3939]' >
            <img src='/image/image-sv2.png' className="md:w-[160px] w-[60px] m-auto pt-[35px] relative img-item-sevice" alt="Không có hình ảnh để hiểu thị" />
            <h3 className='text-center mt-[5px] text-[20px]'>Insurance</h3>
          </div>
        </div> */}
        {showProduct === 1 && (
          <>
            <h3 className='uppercase text-[#fb3939] text-[22px] font-semibold mt-[30px] services-prod-title' >Sản phẩm F&B</h3>
            <div className='services-content-prod'>
              <ServiceBox
                title={"POS Bán Hàng"}
                img='/logo/logo2.jpg'
                content={"Cung cấp chức năng đặt hàng, tìm kiếm, in hóa đơn, tích hợp cổng thanh toán."}
              />
              <ServiceBox
                title={"Order Tại Bàn"}
                img='/logo/logo2.jpg'
                content={"Gọi món nhanh chóng, không cần người phục vụ qua mã QR. Đặt là có ngay."}
              />
              <ServiceBox
                title={"Kitchen"}
                img='/logo/logo2.jpg'
                content={"Tiếp nhận món tại bếp qua ứng dụng Kitchen. Quan sát được trạng thái món."}
              />
              {/* <ServiceBox
                title={"App Ò Ó"}
                img='/logo/logo1.jpg'
                content={"Công nghệ số quy trình phục vụ (Nhanh, tiết kiệm, chính xác) với giá cả hợp lý"}
              /> */}

            </div>
          </>

        )}
        {
          showProduct === 2 && (
            <>
              <h3 className='uppercase text-[#fb3939] text-[22px] ml-[180px] font-semibold mt-[30px] services-prod-title'>Sản phẩm Manufacturing</h3>
              <div className='services-content-prod'>
                <ServiceBox
                  title={"Portal"}
                  img='/logo/logo4.png'
                  content={"Giải pháp tiện ích quản lí toàn bộ quy trình sản xuất qua website. Tối ưu tăng hiệu suất kinh doanh."}
                />
                <ServiceBox
                  title={"App Ò Ó"}
                  img='/logo/logo1.jpg'
                  content={"Chấm công mỗi ngày qua ứng dụng chấm công. Quản lý qua mạng nội bộ. Xếp ca ca trực quan."}
                />
                <ServiceBox
                  title={"App CHÔM CHÔM"}
                  img='/logo/logo.jpg'
                  content={"Công nghệ OCR nhận dạng thông tin qua hình ảnh. Quét mọi văn bản và kết xuất file PDF."}
                />

                {/* <ServiceBox
                  title={"App Ò Ó"}
                  img='/logo/logo1.jpg'
                  content={"Công nghệ số quy trình phục vụ (Nhanh, tiết kiệm, chính xác) với giá cả hợp lý"}
                /> */}

              </div>
            </>
          )
        }
        {
          showProduct === 3 && (
            <>
              <h3 className='uppercase text-[#fb3939] text-[22px] ml-[180px] font-semibold mt-[30px] services-prod-title'>Sản phẩm INSURANCE</h3>
              <div className='services-content-prod'>
                <ServiceBox
                  title={"Baohiemxe.ai"}
                  img='/logo/logo5.png'
                  content={"Áp dụng công nghệ ocr quét giấy tờ tự động. Mua bảo hiểm chỉ cần vài cú chạm."}
                />
                {/* <ServiceBox
                  title={"App CHÔM CHÔM"}
                  img='/logo/logo.jpg'
                  content={"Công nghệ số quy trình phục vụ (Nhanh, tiết kiệm, chính xác) với giá cả hợp lý"}
                />

                <ServiceBox
                  title={"App Ò Ó"}
                  img='/logo/logo1.jpg'
                  content={"Công nghệ số quy trình phục vụ (Nhanh, tiết kiệm, chính xác) với giá cả hợp lý"}
                />
                <ServiceBox
                  title={"App CHÔM CHÔM"}
                  img='/logo/logo.jpg'
                  content={"Công nghệ số quy trình phục vụ (Nhanh, tiết kiệm, chính xác) với giá cả hợp lý"}
                /> */}
              </div>
            </>
          )
        }


        {/* <ul className={styles.servicesItemsList}>
          <li className={styles.servicesItems}>
            <img
              width="140px"
              height="140px"
              src={fastFood}
              alt="Server Security Img"
            />
            <h2 className={styles.servicesItemsTitle}>F&B</h2>
            <p className={styles.servicesItemsDes}>
              We design management systems for marketing, kitchen, business, ..
              all the things that help you manage easily & control your company.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam
            </p>
          </li>
          <li className={styles.servicesItems}>
            <img
              width="140px"
              height="140px"
              src={fast}
              alt="Server Security Img"
            />
            <h2 className={styles.servicesItemsTitle}>Productivity</h2>
            <p className={styles.servicesItemsDes}>
              We've designed tools that help you increase your productivity, cut
              off any extra task which can be automatic. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam
            </p>
          </li>
          <li className={styles.servicesItems}>
            <img width="140px" height="140px" src={production} alt="img" />
            <h2 className={styles.servicesItemsTitle}>Production</h2>
            <p className={styles.servicesItemsDes}>
              You don't need to check every single product anymore with our
              Storagement Warehouse Management System Sofware. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam
            </p>
          </li>
          <li className={styles.servicesItems}>
            <img width="140px" height="140px" src={software} alt="img" />
            <h2 className={styles.servicesItemsTitle}>Software</h2>
            <p className={styles.servicesItemsDes}>
              We've expert team to develop your own multicrossing interface
              software. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ut enim ad minim veniam
            </p>
          </li>
        </ul> */}
      </div>
    </>
  );
};

export default Services;
