import {
    faFacebookSquare,
    faPinterestSquare,
    faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
// import logobg from '/logo/favicon.png';
// import gg from '/image/badge-app-store.png';
// import appstore from '/image/badge-google-play.png';
import './FooterStyles.css';
const Footer = () => {
    return (
        <footer>
            <div className="flex gap-[3rem] bg-red-900 text-gray-100 footer-container">
                <div className="ml-[40px] lg:ml-[20px] footer-content-drs">
                    <div className="flex">
                        <h2 className="text-3xl font-bold">BAGang.ai</h2>
                        <img src='/logo/favicon.png' alt='Không có ảnh để hiển thị' className="footer-logo-img" />
                    </div>

                    <h2 className="text-[25px] mt-[10px] font-medium uppercase">Công ty cổ phần công nghệ Bagang</h2>
                    <ul className="mt-[15px]">
                        <li className="flex">
                            <i className="mr-2 text-[18px]"><ion-icon name="business-outline"></ion-icon></i>
                            170 Lê Hồng Phong, Khu Phố 5, Thủ Dầu Một, Bình Dương
                        </li>
                        <li className="flex">
                            <i className="mr-2 text-[18px]"><ion-icon name="call-outline"></ion-icon></i>
                            028 3622 2084
                        </li>
                        <li className="flex ">
                            <i className="mr-2 text-[18px]"><ion-icon name="mail-outline"></ion-icon></i>
                            buuboi@bagang.ai
                        </li>
                    </ul>
                    <h3 className="mt-[10px]">Tải các ứng dụng của Bagang tại AppStore hoặc GooglePlay</h3>
                    <div className="flex justify-start mt-[10px]">
                        <a className="w-[150px] mr-5" href="https://apps.apple.com/us/app/bagang/id1568382221" target="_blank" rel='noreferrer'>
                            <img src='/image/badge-app-store.png' alt="bagang-apple-store" />
                        </a>
                        <a className="w-[150px]" href="https://play.google.com/store/apps/details?id=com.giong" target="_blank" rel='noreferrer'>
                            <img src='/image/badge-google-play.png' alt="bagang-ch-play" />
                        </a>
                    </div>

                    <h2 className="mt-[10px]">Theo dõi Bagang tại</h2>
                    <div className="my-[5px] text-[2.6rem] flex gap gap-2">
                        <a href="https://www.facebook.com/bagangtech" target={'_blank'} rel={'noreferrer'} className="text-blue-600 ">
                            <FontAwesomeIcon icon={faFacebookSquare} />
                        </a>
                        {/* <a href="" className="text-blue-400 ">
                            <FontAwesomeIcon icon={faTwitterSquare} />
                        </a>
                        <span className="text-red-600 ">
                            <FontAwesomeIcon icon={faPinterestSquare} />
                        </span> */}
                    </div>
                </div>
                <div className="md:w-[45%] w-full footer-content-srv">
                    <h3 className="text-[1.6rem] font-semibold mb-[1rem] item-srv-title ">Dịch vụ</h3>
                    <ul className="flex flex-col md:my-[1.7rem] my-[15px] gap md:gap-[1rem] gap-[5px] font-light">
                        <li>F&B</li>
                        <li>Manufacturing</li>
                        <li>INSURANCE</li>
                    </ul>
                </div>
                <div className="md:w-[45%] w-full footer-content-poli">
                    <h3 className="text-[1.6rem] font-semibold mb-[1rem] item-srv-title ">Thông tin & điều khoản</h3>
                    <ul className="flex flex-col md:my-[1.7rem] my-[15px] gap md:gap-[1rem] gap-[5px] font-light">
                        <li>Chính sách bảo mật</li>
                        <li>Chính sách và điều khoản</li>
                    </ul>
                </div>
            </div>
            <div className="bg-gray-900 text-gray-100 py-[1.5rem] text-center text-[1rem]">
                <p>
                    © Copyright {new Date().getFullYear()} <span className="text-red-600">Bagang.ai</span>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
