import MainLayout from "../../layouts/MainLayout"
import JDForm from "./JDForm"

const JDAdmin = () => {
    return (
        <MainLayout>
            <div className="w-full md:w-[80%] m-auto">
                <JDForm title='Admin'>
                    <h2 className="text-left text-[20px] font-bold my-2">ADMINISTRATOR</h2>
                    <h2 className="text-left text-[20px] font-bold my-2">Phụ trách:</h2>
                    <ul className="">
                        <li className="pt-[4px]">- Xử lý thông tin bán hàng và xác thực thông tin giao dịch</li>
                        <li className="pt-[4px]">- Thu giữ, sắp xếp hồ sơ thông tin khách hàng</li>
                        <li className="pt-[4px]">- Thu thập, phân tích,báo cáo số liệu bán hàng để phòng ban lập chiến lược kinh doanh</li>
                        <li className="pt-[4px]">- Thường xuyên kiểm tra,xác định sai lệch số liệu và cập nhật hồ sơ kịp thời</li>
                        <li className="pt-[4px]">- Cập nhật và định giá sản phẩm nguồn cung ứng</li>
                        <li className="pt-[4px]">- Hỗ trợ duyệt quyền truy cập dữ liệu cho các phòng bạn</li>
                        <li className="pt-[4px]">- Thực hiện các biện pháp bảo mật dữ liệu khi trao quyền truy cập</li>
                        <li className="pt-[4px]">- Hỗ trợ xây dựng và cải tiến quy trình tự động hóa số liệu trong công ty</li>
                        <li className="pt-[4px]">- Tạo bản sao dữ liệu</li>
                    </ul>
                    <h2 className="text-left text-[20px] font-bold my-2">Yêu cầu:</h2>
                    <ul className="">
                        <li className="pt-[4px]">- Kinh nghiệm ở vị trí tương đương 1 năm</li>
                        <li className="pt-[4px]">- Khả năng trích xuất số liệu chi tiết từ tập dữ liệu lớn</li>
                        <li className="pt-[4px]">- Chịu áp lực tốt với nhịp độ nhanh trong công việc</li>
                        <li className="pt-[4px]">- Kỹ năng giao tiếp,trình bày thông tin bằng văn bản và lời nói</li>
                        <li className="pt-[4px]">- Cập nhật và định giá sản phẩm nguồn cung ứng</li>
                        <li className="pt-[4px]">- Kinh nghiệm giải quyết sai lệch tồn kho</li>
                        <li className="pt-[4px]">- Chủ động học hỏi và thích nghi nhanh với công nghệ mới</li>
                        <li className="pt-[4px]">- Tỉ mỉ, trách nhiệm và trung thực</li>
                    </ul>
                    <h2 className="text-left text-[20px] font-bold my-2">Quyền lợi:</h2>
                    <ul className="">
                        <li className="pt-[4px]">- Mức lương hấp dẫn lên đến 90.000.000 VND/năm</li>
                        <li className="pt-[4px]">- Ngày phép theo quy định nhà nước</li>
                        <li className="pt-[4px]">- Bảo hiểm theo luật lao động Việt Nam</li>
                        <li className="pt-[4px]">- Được đóng góp và ghi nhận giá trị trong môi trường startup trẻ trung, tích cực</li>
                        <li className="pt-[4px]">- Cung cấp công nghệ mới phục vụ công việc</li>
                        <li className="pt-[4px]">- Sử dụng sản phẩm và dịch vụ Eco Gióng với nhiều ưu đãi</li>
                    </ul>
                    <h2 className="mb-5">Hãy gửi CV về buuboi@bagang.ai - Tiêu đề: [Data Administrator] - Họ & Tên</h2>
                    <a href="mailto:buuboi@bagang.ai" className="bg-[#f74334] text-white px-3 py-3 rounded-[5px] text-[18px]">Đăng kí ứng tuyển</a>
                </JDForm>
            </div>
        </MainLayout>

    )
}

export default JDAdmin