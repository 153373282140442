import { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import './NavbarStyles.css';
// import logobg from '/logo/favicon.png';


export default function TransparentNavBar() {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  return (
    <nav class="bg-transparent fixed top-0 left-0 w-full h-[80px] border-gray-200 px-2 sm:px-4 px-10 py-2.5 flex items-center z-30">
      <div class="container flex flex-wrap items-center justify-between mx-auto">
        <a href="https://flowbite.com/" class="flex items-center">
          <img src="/image/logo.png" class="w-16 mr-3" alt="Flowbite Logo" />
          <span class="self-center text-xl font-semibold whitespace-nowrap">BAGang.AI</span>
        </a>
        <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false" onClick={() => setIsOpenMenu(true)}>
          <span class="sr-only">Open main menu</span>
          <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
        </button>
        <div class={`${isOpenMenu? 'block' : 'hidden'} w-full h-screen lg:h-auto z-30 bg-black bg-opacity-80 lg:bg-opacity-0 lg:block lg:w-auto fixed top-0 left-0 lg:relative`} id="navbar-default" onClick={() => setIsOpenMenu(false)}>
          <ul class="fixed lg:relative top-0 right-0 h-full lg:flex lg:space-x-[3vw] xl:space-x-[5vw] text-[1.2rem] font-medium bg-red-700 lg:bg-transparent w-[250px] lg:w-fit z-20" onClick={(e) => e.stopPropagation()}>
            <li className="w-full lg:w-fit my-8 lg:my-0 text-center">
              <a href="#Banner" class="bblock py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-red-500 md:p-0" aria-current="page">Trang Chủ</a>
            </li>
            <li className="w-full lg:w-fit my-8 lg:my-0 text-center">
              <a href="#Services" class="block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-red-500 md:p-0">Giải Pháp</a>
            </li>
            <li className="w-full lg:w-fit my-8 lg:my-0 text-center">
              <a href="#Recruit" class="block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-red-500 md:p-0">Sản Phẩm</a>
            </li>
            <li className="w-full lg:w-fit my-8 lg:my-0 text-center">
              <a href="#AboutUs" class="block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-red-500 md:p-0">Tuyển Dụng</a>
            </li>
            <li className="w-full lg:w-fit my-8 lg:my-0 text-center">
              <a href="#Contact-us" class="block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-red-500 md:p-0">Liên Hệ</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
