

export default function ServiceBox({ title, img, content }) {
    return (
        <div className="cart">
            <div className="face face1">
                <div className="content">
                    <img src={img} alt='Hình ảnh không tồn tại' className="md:w-[80%] w-[65%] m-auto" />
                    <h3 className="md:text-[20px] text-[15px] font-semibold text-center mb-[10px]">{title}</h3>
                    <a className="text-[12px] font-medium ml-[10px] bg-[#fb3939] text-[#fff] uppercase p-2 cursor-pointer">Xem Thêm</a>
                </div>
            </div>
            <div className="face face2">
                <p>{content}</p>
            </div>
        </div>

    )
}