import { AnimationOnScroll } from 'react-animation-on-scroll';


export default function Banner() {
  return (
    <div>
      <div className='h-[100vh] w-full flex items-center bg-center bg-cover' style={{ backgroundImage: "url('/image/bg-home-3.jpg')" }}>
        <div className='font-medium text-white pb-[50px] pl-[20px] md:pl-[50px] lg:pl-[150px] pr-[20px] md:pr-[100px] lg:pr-[30vw] animate__animated animate__slideInDown'>
            <h1 className='font-bold text-[50px] lg:text-[100px]'>BAGang.AI</h1>
            <h2 className='leading-[40px] text-[25px] mb-4'>Giải pháp công nghệ tối ưu cho doanh nghiệp vừa và nhỏ</h2>
            <h3 className='leading-[40px]'>CÔNG TY CỔ PHẦN CÔNG NGHỆ BAGANG (BAGANG TECHNOLOGY JOINT STOCK COMPANY) - Thành lập ngày 20/08/2021. BAGang Tech là startup công nghệ cung cấp các giải pháp thông minh giúp doanh nghiệp tối ưu hóa quá trình vận hành và kinh doanh một cách hiệu quả.</h3>
        </div>
      </div>
    </div >
  );
};

