import MainLayout from "../../layouts/MainLayout"
import JDForm from "./JDForm"

const JDITQCExecutive = () => {
    return (
        <MainLayout>
            <div className="w-full md:w-[80%] m-auto">
                <JDForm title='IT QC EXECUTIVE'>
                    <h2 className="text-left text-[20px] font-bold my-2">IT QC EXECUTIVE</h2>
                    <p>Với nhu cầu mở rộng mạng lưới, Bagang đã có những anh tài viết nên những phần mềm vận hàng và quản lý doanh nghiệp hiệu quả. Để hỗ trợ đội phát triển phần mềm,
                        Bagang muốn chiêu mộ những IT QC Executive chuyên tìm bugs và đưa ra phương án khắc phục để các phần mềm hoạt động trơn tru.</p>
                    <h2 className="text-left text-[20px] font-bold my-2">Công Việc của 1 IT QC Executive - “Healing Magician”</h2>
                    <ul className="">
                        <li className="pt-[4px]">- Test và report bugs đến cho đội Development</li>
                        <li className="pt-[4px]">- Phân tích bug, report kết quả feedback từ khách hàng, cấp trên</li>
                        <li className="pt-[4px]">- Chủ động lên kế hoạch test cho các dự án</li>
                        <li className="pt-[4px]">- Thường xuyên tìm hiểu các sản phẩm tương tự để đánh giá sản phẩm của công ty</li>
                        <li className="pt-[4px]">- Đánh giá thị trường và ghi nhận phản hồi của người dùng sản phẩm</li>
                        <li className="pt-[4px]">- So sánh, báo cáo kết quả mong đợi và kết quả thực tế của sản phẩm</li>
                        <li className="pt-[4px]">- Đảm bảo chất lượng ngôn ngữ lập trình của sản phẩm theo tiêu chuẩn chất lượng cao</li>
                        <li className="pt-[4px]">- Hỗ trợ phát triển các chương trình kiểm tra lỗi và bảo mật của sản phẩm</li>
                        <li className="pt-[4px]">- Định hướng thị trường và đóng góp ý kiến phát triển sản phẩm mới</li>
                    </ul>
                    <h2 className="text-left text-[20px] font-bold my-2">Bạn Tự Tin vào Magic của Bạn trong việc</h2>
                    <ul className="">
                        <li className="pt-[4px]">- Am hiểu chuyên ngành CNTT, quản trị mạng hoặc các ngành liên quan</li>
                        <li className="pt-[4px]">- Đam mê kiểm soát, kiểm tra hiệu suất web, app & các giải pháp công nghệ</li>
                        <li className="pt-[4px]">- Hiểu biết về ngôn ngữ lập trình (Python, Java)</li>
                        <li className="pt-[4px]">- Mindset & tiêu chuẩn chất lượng cao, không ngừng cải tiến chất lượng</li>
                        <li className="pt-[4px]">- Kỹ tính, khó chịu kể cả với những lỗi nhỏ hoặc giao diện khó sử dụng</li>
                        <li className="pt-[4px]">- Tư duy logic & nhạy bén với xu hướng công nghệ, trải nghiệm khách hàng</li>
                        <li className="pt-[4px]">- Kỷ luật cao, chủ động trong công việc, quản lý thời gian tốt</li>
                    </ul>
                    <h2 className="text-left text-[20px] font-bold my-2">Tại sao nên chọn chúng tôi?</h2>
                    <ul className="">
                        <li className="pt-[4px]">- Mức lương thỏa thuận,cạnh tranh so với thị trường</li>
                        <li className="pt-[4px]">- Phát triển sự nghiệp trong lĩnh vực IT cùng những cộng sự tài năng & cùng chí hướng đam mê công nghệ hướng tới trải nghiệm khách hàng</li>
                        <li className="pt-[4px]">- Môi trường làm việc năng động theo thể chế nhân tài, cơ hội thăng tiến nhanh </li>
                        <li className="pt-[4px]">- cung cấp bạn những công cụ & hướng đi phù hợp thế mạnh của bạn để bạn phát triển trở thành những nhà lãnh đạo công nghệ tương lai</li>
                        <li className="pt-[4px]">- Chế độ nghỉ phép, BHXH theo quy định nhà nước</li>
                    </ul>
                    <h2 className="mb-5">Hãy gửi CV về buuboi@bagang.ai - Tiêu đề: [ITQC Executive] - Họ & Tên</h2>
                    <a href="mailto:buuboi@bagang.ai" className="bg-[#f74334] text-white px-3 py-3 rounded-[5px] text-[18px]">Đăng kí ứng tuyển</a>
                </JDForm>
            </div>
        </MainLayout>

    )
}

export default JDITQCExecutive