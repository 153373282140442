const RecruitBox = ({ title, titlejd }) => {
    return (
        <div className="border-b-2 justify-between items-center pb-[10px] my-[10px] recr-container">
            <div >
                <h2 className="text-[20px] font-semibold">{title}</h2>
                <p className="text-[18px]">Thành phố Hồ Chí Minh | Liên tỉnh</p>
            </div>
            <div>
                <a href={`recruit/${titlejd}`} className="bg-[#f74334] text-white text-[15px] text-center py-[10px] px-[25px] rounded-[5px] cursor-pointer item-bt">Chi tiết</a>
            </div>

        </div>
    )
}
export default RecruitBox;