import { Route, Routes } from "react-router-dom";
import JDAdmin from "./components/JD/JDAdmin";
import JDFrontenđeveloper from "./components/JD/JDFrontendDeveloper";
import JDITIntern from "./components/JD/JDITIntern";
import JDITQCExecutive from "./components/JD/JDITQCExecutive";
import Home from "./routes/Home";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/recruit/admin" element={<JDAdmin />} />
        <Route path="/recruit/qc-executive" element={<JDITQCExecutive />} />
        <Route path="/recruit/frontend-developer" element={<JDFrontenđeveloper />} />
        <Route path="/recruit/it-intern" element={<JDITIntern />} />
      </Routes>
    </>
  );
}

export default App;
