import MainLayout from "../../layouts/MainLayout"
import JDForm from "./JDForm"

const JDFrontenđeveloper = () => {
    return (
        <MainLayout>
            <div className="w-full md:w-[80%] m-auto">
                <JDForm title='IT Front-end Developer'>
                    <h2 className="text-left text-[20px] font-bold my-2">IT Front-end Developer</h2>
                    <p>Với nhu cầu mở rộng mạng lưới, Bagang chuyên tâm tìm kiếm những anh tài công nghệ để cùng nhau viết nên những phần mềm vận hành và quản lý doanh nghiệp hiệu quả.Chúng tôi luôn hướng đến những sản phẩm và dịch vụ cao;
                        Vì thế, chúng tôi cần bạn - IT Front-end Developer để bảo đảm phần nhìn lẫn công năng của phần mềm.</p>
                    <h2 className="text-left text-[20px] font-bold my-2">Công Việc của 1 IT Front-end Developer – “Creation Magician”</h2>
                    <ul className="">
                        <li className="pt-[4px]">- Tham gia lập trình giao diện Mobile App, Website, Các ứng dụng hỗ trợ doanh nghiệp</li>
                        <li className="pt-[4px]">- Đảm bảo tính khả thi trong việc lập trình kỹ thuật các thiết kế UI/UX</li>
                        <li className="pt-[4px]">- Tối ưu hoá ứng dụng để có tốc độ & khả năng mở rộng tối đa</li>
                        <li className="pt-[4px]">- Đảm bảo tất cả dữ liệu nhập vào từ người dùng đều được xác thực trước khi gửi đến Back-end</li>
                        <li className="pt-[4px]">- Cải thiện liên tục giao diện để đáp ứng nhu cầu trải nghiệm của khách hàng  - Kiểm tra hiệu năng của phần mềm  </li>
                    </ul>
                    <h2 className="text-left text-[20px] font-bold my-2">Bạn Tự Tin vào Magic của Bạn trong việc</h2>
                    <ul className="">
                        <li className="pt-[4px]">- Có kinh nghiệm chuyên ngành CNTT, quản trị mạng hoặc các ngành liên quan ít nhất 2 năm kinh  nghiệm  </li>
                        <li className="pt-[4px]">* Nếu bạn chưa có nhiều kinh nghiệm nhưng tự tin vào năng lực của bản thân và mong muốn thử hợp tác, đừng ngại gửi email cho bộ phận Tìm Kiếm Nhân Tài</li>
                        <li className="pt-[4px]">- Kỹ tính, khó chịu với chất lượng giao diện thấp hoặc khó sử dụng</li>
                        <li className="pt-[4px]">- Nhạy bén với xu hướng công nghệ, trải nghiệm khách hàng</li>
                        <li className="pt-[4px]">- Kỷ luật cao, chủ động trong công việc, quản lý thời gian tốt</li>
                        <li className="pt-[4px]">- Có kinh nghiệm trong Frontend: Wordpress, HTML, CSS, JavaScript</li>
                    </ul>
                    <h2 className="text-left text-[20px] font-bold my-2">Tại sao nên chọn chúng tôi?</h2>
                    <ul className="">
                        <li className="pt-[4px]">- Lương thoả thuận (15 – 20,000,000 VNĐ) tuỳ vào khả năng</li>
                        <li className="pt-[4px]">- Phát triển sự nghiệp trong lĩnh vực IT cùng những cộng sự tài năng & cùng chí hướng đam mê  công nghệ hướng tới trải nghiệm khách hàng</li>
                        <li className="pt-[4px]">-  Môi trường làm việc năng động theo thể chế nhân tài, cơ hội thăng tiến nhanh </li>
                        <li className="pt-[4px]">- Chế độ nghỉ phép, BHXH theo quy định nhà nước</li>
                    </ul>
                    <h2 className="mb-5">Hãy gửi CV về buuboi@bagang.ai - Tiêu đề: [ITDeveloper] - Họ & Tên</h2>
                    <a href="mailto:buuboi@bagang.ai" className="bg-[#f74334] text-white px-3 py-3 rounded-[5px] text-[18px]">Đăng kí ứng tuyển</a>
                </JDForm>
            </div>
        </MainLayout>

    )
}

export default JDFrontenđeveloper