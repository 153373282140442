import React, { useRef } from "react";
import './ContactStyles.css';

const map = {
  iframe: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.35564138615!2d106.68184841462262!3d10.784049792316319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f2e9a0a6da1%3A0xa37f6550fe104e05!2zMTgwIEzDvSBDaMOtbmggVGjhuq9uZywgUGjGsOG7nW5nIDksIFF14bqtbiAzLCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmgsIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1668653938285!5m2!1svi!2s" width="600" height="450" style="border:0; width:100%;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
};
function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} className='map-img w-[40%]'
    />
  );
}
const Contact = () => {
  return (
    <div className="contact-container">
      <div className="flex md:w-[80%] w-full m-auto items-center pb-[35px]">
        <b className="br-service"></b>
        <span className="md:text-[50px] text-[30px] font-bold text-[#0e294d] md:mx-[25px] mx-[10px] md:pb-[15px] pb-[5px]">
          Liên hệ
        </span>
        <b className="br-service"></b>

      </div>
      <div className="flex justify-center gap-[5%] contact-content">
        <Iframe iframe={map["iframe"]} />
        <div className="contact-img w-[40%]">
          <div>
            <h2 className="text-[30px] font-semibold">BAGang</h2>
            <ul className="mt-[10px]">
              <li className="text-[16px] flex items-center mb-1">
                <i className="mr-2 text-[18px]"><ion-icon name="home-outline"></ion-icon></i>
                180 Lý Chính Thắng, Phường 09, Quận 03, Tp. Hồ Chí Minh
              </li>
              <li className="text-[16px] flex items-center mb-1">
                <i className="mr-2 text-[18px]"><ion-icon name="business-outline"></ion-icon></i>
                170 Lê Hồng Phong, Khu Phố 5, Thủ Dầu Một, Bình Dương
              </li>
              <li className="text-[16px] flex items-center mb-1">
                <i className="mr-2 text-[18px]"><ion-icon name="call-outline"></ion-icon></i>
                028 3622 2084
              </li>
              <li className="text-[16px] flex items-center mb-1">
                <i className="mr-2 text-[18px]"><ion-icon name="mail-outline"></ion-icon></i>
                buuboi@bagang.ai
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
    // <div className={styles.contactContainer}>
    //   <div className={styles.contactTitleContainer}>
    //     <h2 className="font-bold text-xl py-4">Contact for Work</h2>
    //     <p className="description">
    //       Please contact us if u had IT problems to solve! We will reply as soon
    //       as we can!
    //     </p>
    //   </div>
    //   <form className="contact-form">
    //     <ul className={styles.contactForm}>
    //       <li className="grid grid-cols-2 gap-3">
    //         <div className={styles.contactItems}>
    //           <input
    //             type="name"
    //             className={styles.contactInput}
    //             placeholder=" "
    //             required
    //           />
    //           <label className={styles.contactLabel}>Full Name:</label>
    //         </div>
    //         <div className={styles.contactItems}>
    //           <input
    //             type="email"
    //             name="floating_email"
    //             id="floating_email"
    //             className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
    //             placeholder=" "
    //             required
    //           />
    //           <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
    //             Email address:
    //           </label>
    //         </div>
    //       </li>
    //       <li className="flex gap-3">
    //         <div className={styles.contactItems}>
    //           <input
    //             type="tel"
    //             name="floating_phone"
    //             id="floating_phone"
    //             className={styles.contactInput}
    //             placeholder=" "
    //             required
    //           />
    //           <label className={styles.contactLabel}>Tel:</label>
    //         </div>
    //       </li>
    //       <li className="flex gap-3">
    //         <div className={styles.contactItems}>
    //           <input
    //             type="tel"
    //             name="floating_"
    //             id="floating_phone"
    //             className={styles.contactInput}
    //             placeholder=" "
    //             required
    //           />
    //           <label className={styles.contactLabel}>Message:</label>
    //         </div>
    //       </li>
    //       <li className="text-center text-xl ">
    //         <button className="text-white bg-slate-400 hover:bg-slate-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm py-2.5 text-center mr-2 mb-2 dark:bg-slate-400 dark:hover:bg-slate-500 dark:focus:ring-slate-800 px-[4rem]">
    //           Send Email
    //         </button>
    //       </li>
    //     </ul>
    //   </form>
    // </div>
  )


};

export default Contact;
