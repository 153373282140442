import MainLayout from "../../layouts/MainLayout"
import JDForm from "./JDForm"

const JDITIntern = () => {
    return (
        <MainLayout>
            <div className="w-full md:w-[80%] m-auto">
                <JDForm title='IT Intern'>
                    <h2 className="text-left text-[20px] font-bold my-2">IT Intern</h2>
                    <h2 className="text-left text-[20px] font-bold my-2">About our IT Projects</h2>
                    <ul className="">We focus on exploiting IT opportunities to minimize time waste, defects & cheating risks in daily F&B and retail operations. Our Objectives are:
                        <li className="pt-[4px]">Guarantee Vietnamese quality</li>
                        <li className="pt-[4px]">Initiatenew thinking & technology</li>
                        <li className="pt-[4px]">Openinternational network</li>
                        <li className="pt-[4px]">Nurturesustainability</li>
                        <li className="pt-[4px]">GenerateWOW service</li>
                    </ul>
                    <h2 className="text-left text-[20px] font-bold my-2">Internship Description</h2>
                    <ul className="">
                        <li className="pt-[4px]">- Work with team members to develop internal software applications and websites that meet the requirements of Board of Directors.
                            Several software applications that you can have hands on: Pick-UpApp, Inventory App, HRM App, CustomerLoyalty App, POS App, etc.</li>
                        <li className="pt-[4px]">- Team work with cross-functional teamsto ensure IT-product quality.</li>
                        <li className="pt-[4px]">- Troubleshoot and monitor issues ofcurrent internal software applications.</li>
                    </ul>
                    <h2 className="text-left text-[20px] font-bold my-2">‍Benefits</h2>
                    <ul className="">
                        <li className="pt-[4px]">- Undergraduate students in InformationTechnology, Computer Science, Computer Engineering or relevant departments</li>
                        <li className="pt-[4px]">- Familiarity with React.JS/ Redux/ ReactNative</li>
                        <li className="pt-[4px]">- Familiarity with .NET Core/ Entity Framework Core</li>
                        <li className="pt-[4px]">- Familiarity with relational database(e.g., SQL Server)</li>
                        <li className="pt-[4px]">- Capability of using C# &Javascript Language</li>
                        <li className="pt-[4px]">- Abilityto interact with APIs</li>
                        <li className="pt-[4px]">- Teamwork, time management andproblem-solving skills with solution-oriented mindset</li>
                        <li className="pt-[4px]">- Proactive and have acan-do attitude to work collaboratively in a fast-paced & challenging environment to help our business thrive</li>
                    </ul>
                    <h2 className="mb-5">Please kindly sendyour CV to buuboi@bagang.ai with the following subject [Position] – [Full Name]</h2>
                    <a href="mailto:buuboi@bagang.ai" className="bg-[#f74334] text-white px-3 py-3 rounded-[5px] text-[18px]">Đăng kí ứng tuyển</a>
                </JDForm>
            </div>
        </MainLayout>

    )
}

export default JDITIntern