import { useState, useEffect} from 'react';
import TransparentNavBar from './TransparentNavBar';

export default function HomeNav() {
  const [isScrolledFarFromTop, setIsScrolledFarFromTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolledFarFromTop = window.scrollY > 0;
      setIsScrolledFarFromTop(isScrolledFarFromTop);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [])

  return (
    <nav className={`fixed top-0 w-full h-[80px] z-30 ${isScrolledFarFromTop? 'text-white' : 'text-white' }`}>
      <TransparentNavBar/>
      {(isScrolledFarFromTop) && <div className={`fixed w-full h-[80px] bg-red-900 z-20`}></div>}
    </nav>
  )
}