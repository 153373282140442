import React from "react";
import './Recruit.css';
import RecruitBox from "./RecruitBox";

const Recruit = () => {
    return (
        <div className="mb-[50px]">
            <div className="recruit-title">
                <div className="flex md:w-[80%] w-[90%] m-auto items-center ">
                    <b className="br-service"></b>
                    <span className="md:text-[50px] text-[25px] font-bold text-[#0e294d] md:mx-[25px] mx-[15px] md:pb-[15px] pb-[5px]">
                        Tuyển dụng
                    </span>
                    <b className="br-service"></b>

                </div>
                <p className="md:text-[21px] text-[15px] text-[#435a6e] mx-[40px] md:mx-0">
                    Bagang tìm bạn đồng hành cho các vị trí
                </p>
            </div>
            <div className="recruit-container">
                <RecruitBox title='Admin' titlejd='admin' />
                <RecruitBox title='IT QC Executive' titlejd='qc-executive' />
                <RecruitBox title='IT Frontend Developer' titlejd='frontend-developer' />
                <RecruitBox title='IT Intern' titlejd='it-intern' />
            </div>
        </div>
    )
}

export default Recruit;