import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import './about.css';

const AboutUs = () => {

  const vidRef = useRef();

  useEffect(() => { vidRef.current.play(); }, []);
  return (
    <>
      <div className="content-about">
        <div className="content-about-left">
          <h2 className="text-[#585858] text-[45px] font-medium mb-[20px]">
            Đầu tư <strong className="text-red-600">ít</strong>, sinh lời <strong className="text-red-600">to</strong>
          </h2>
          <h4 className="text-[#707070] font-light text-[20px] text-left leading-[32px] md:mb-[45px] mb-[25px]">
            Đầu tư ngay từ ban đầu quy trình công nghệ thông minh phục vụ con người, khắc phục khó khăn của quy trình làm việc thủ công. Quản lý chặt chẽ hệ thống.
            Giải pháp tốt, giá tốt, hữu ích. Liên hệ ngay với chúng tôi để được tư vấn và được nhiều ưu đãi hấp dẫn.
          </h4>
          <div className="bg-[#f74334] w-fit text-[0.9rem] p-4 text-white rounded font-bold">
            <a href="#Services">Xem ngay</a>
          </div>
        </div>
        <div className="content-about-right">
          {/* <a
            className="relative"
            target="popup"
            href="https://www.youtube.com/watch?v=DcCISK3sCYg&list=RDMMUl9G4bVlLss&index=11"
          > */}
          {/* <span className="z-1 p-6 text-black text-2xl  absolute bg-white rounded-full  left-[45%] top-[35%] lg:left-[48%] lg:top-[40%] ">
              <FontAwesomeIcon
                icon={faPlay}
                className="absolute right-[30%] bottom-[25%]"
              />
            </span> */}
          <div className="video-tvc">
            <video controls={false} autoplay height={500} loop ref={vidRef} muted>
              <source src='/image/videoTVC.mp4' type="video/mp4" />
            </video>
          </div>

          {/* </a> */}
        </div>
      </div>
    </>
  );
};

export default AboutUs;
