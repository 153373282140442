
const JDForm = ({ title, children }) => {
    return (
        <div className="p-7">
            <div className="text-center border-b border-solid border-slate-300 pb-[15px] mb-5 lg:flex lg:justify-between lg:items-center">
                <h2 className="text-[20px] font-bold">Thông tin tuyển dụng</h2>
                <div className="flex justify-center pt-3 lg:pt-0"><a href="/" className="text-gray-400 hover:text-black text-[15px]">Trang chủ</a><span className="mx-2">/</span><h3 className="font-semibold text-[15px]">Tuyển dụng</h3></div>
            </div>
            <div>
                <h2 className="text-center text-[30px] font-bold">{title}</h2>
                <h3 className="text-center text-[20px] font-semibold">Hồ Chí Minh | Full time</h3>
                <div>{children}</div>
            </div>
        </div>
    )
}

export default JDForm