import React from "react";
import Footer from "../components/Footer/Footer";

export default function MainLayout({ children }) {
    return (
        <>
            <main className="min-h-screen">{children}</main>
            <Footer />
        </>
    );
}